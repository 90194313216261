<template>
  <tr
    class="black-list-item-tr"
  >
    <td
      class="table-id"
    >
      {{ listData.id }}
    </td>
    <td
      class="table-networkId"
    >
      {{ listData.netId || listData.network }}
    </td>
    <td
      class="table-address"
    >
      {{ listData.address }}
    </td>
    <td
      class="table-created"
    >
      {{ dataFormatting(listData.created, 'DD.MM.YY') }}
    </td>
    <td
      class="table-comment"
    >
      {{ listData.comment || listData.note }}
    </td>
    <td class="table-edit"
        @click="changeStatusHandler"
    >
      <button class="delete">
        <svg
          v-if="listData.isActive === true"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <rect
            x="6"
            y="6"
            width="12"
            height="12"
            fill="#FF603D"
            stroke="red"
            stroke-width="2"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#02C076"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polygon
            points="8,5 19,12 8,19"
            fill="#02C076"
          />
        </svg>
      </button>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import BlackListService from '@/services/black-list.service';

export default {
  name: "BlackListItem",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    listItem: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    activeTab: {
      required: true
    }
  },
  data() {
    return {
      mainDomain: process.env.VUE_APP_MAIN_DOMAIN,
      listData: {}
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.listData = this.listItem
  },
  beforeUnmount() {

  },
  methods: {
    dataFormatting(value, format) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(format)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    changeStatusHandler() {
      const isConfirmed = confirm('Are you sure?')

      if (isConfirmed && this.activeTab === 'deposits') {
        BlackListService.setDepositRecord(this.listData.id,!this.listData.isActive).then((result) => {
          if (result.status === 200) {
            this.listData.isActive = !this.listData.isActive;
          }
        }).catch((error) => {
          console.log(error.response.data.result)
        })
      }

      if (isConfirmed && this.activeTab === 'withdrawal') {
        BlackListService.setWithdrawalRecord(this.listData.id,!this.listData.isActive).then((result) => {
          if (result.status === 200) {
            this.listData.isActive = !this.listData.isActive;
          }
        }).catch((error) => {
          console.log(error.response.data.result)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

  .black-list-item-tr {
    height: 56px;
    border-bottom: 1px solid #EAEFF4;
    transition: .3s;

    &:hover {
      background: #F5F9FF;
    }

    td {
      position: relative;
      color: $black-color1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.28px;

      span {
        color: $gray-color;
      }
    }

    td.table-address {
      width: auto;
    }

    td.table-id {
      padding-left: 32px;
      width: auto;
    }

    td.table-edit {
      display: flex;
      align-items: center;
      padding-right: 32px;
      height: 52px;
      gap: 6px;

      button {
        display: flex;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        outline: none;
        border: none;
        cursor: pointer;
        box-sizing: border-box;
        transition: 0.3s;
      }

      button.edit, button.link {
        justify-content: center;
        background: $light-gray-color;
      }

      button.link.disabled {
        cursor: not-allowed;

        svg path {
          fill: #A1BBD9;
        }
      }
    }
  }

</style>
