import {createWebHistory, createRouter} from "vue-router";
import Login from '../pages/auth/Login.vue'
import Dashboard from '../pages/dashboard/Dashboard'
import UsersList from "../pages/users/UsersList";
import CoinsList from "../pages/coins/CoinsList";
import ProgramsList from "../pages/partner-programs/ProgramsList"
import PaymentsList from "../pages/payments/PaymentsList";
import ExchangesList from "../pages/exchanges/ExchangesList";
import ExchangesList2 from "../pages/exchanges-2/ExchangesList";
import PartnerStat from "../pages/partner-stat/PartnerStat";
import Settings from "../pages/settings/Settings";
import SubAccounts from "../pages/sub-accounts/SubAccounts";
import UserBalances from "../pages/user-balance/UserBalances";
import Coins1inchList from "@/pages/coins/sub-pages/Coins1inchList";
import ExchangesList1inch from "@/pages/exchanges/sub-pages/1inch/ExchangesList1inch";
import ExchangesListMulti from "@/pages/exchanges/sub-pages/multi/ExchangesListMulti";
import AddressLockedList from "@/pages/address-locked/AddressLockedList";
import Balance1inchList from "@/pages/balance/balance1inch/Balance1inchList";
import PartnerVolume from "@/pages/partner-volume/PartnerVolume.vue";
import WidgetCreate from "@/pages/widget-create/WidgetCreate.vue";
import TradeBalancePage from "@/pages/admin-trade/balance/TradeBalancePage.vue";
import TradeOrdersPage from "@/pages/admin-trade/orders/TradeOrdersPage.vue";
import TradeWithdrawalPage from "@/pages/admin-trade/withdrawal/TradeWithdrawalPage.vue";
import CreateOrder from "@/pages/admin-trade/create-order/CreateOrder.vue";
import CreateWithdrawal from "@/pages/admin-trade/create-withdrawal/CreateWithdrawal.vue";
import CreateTransfer from "@/pages/admin-trade/create-transfer/CreateTransfer.vue";
import CollectProfit from "@/pages/admin-trade/collect-profit/CollectProfit.vue";
import TradeAccounts from "@/pages/admin-trade/accounts/TradeAccounts.vue";
import CreateAccount from "@/pages/admin-trade/create-account/CreateAccount.vue";
import RateRequest from "@/pages/rate-request/RateRequest.vue";
import RateRequestCoins from "@/pages/rate-request/sub-pages/not-found/RateRequestCoins.vue";
import RateRequestDate from "@/pages/rate-request/sub-pages/by-date/RateRequestDate.vue";
import CreateDeposit from "@/pages/admin-trade/deposit/CreateDeposit.vue";
import RateCorrection from "@/pages/rate-correction/RateCorrection.vue";
import DashboardIcon from "@/assets/img/icons/menu/dashboardIcon.vue";
import CoinsIcon from "@/assets/img/icons/menu/coinsIcon.vue";
import ExchangesIcon from "@/assets/img/icons/menu/exchangesIcon.vue";
import UserIcon from "@/assets/img/icons/menu/userIcon.vue";
import PartnersPayIcon from "@/assets/img/icons/menu/partnersPayIcon.vue";
import PartnerStatsIcon from "@/assets/img/icons/menu/partnerStatsIcon.vue";
import PartnersProgramIcon from "@/assets/img/icons/menu/partnersProgramIcon.vue";
import SettingsIcon from "@/assets/img/icons/menu/settingsIcon.vue";
import UsersIcon from "@/assets/img/icons/menu/usersIcon.vue";
import RateRequestIcon from "@/assets/img/icons/menu/rateRequestIcon.vue";
import WidgetIcon from "@/assets/img/icons/menu/widgetIcon.vue";
import Balance1inchTotal from "@/pages/balance/balance-total/BalanceMirror.vue";
import BalanceMirror from "@/pages/balance/balance-total/BalanceMirror.vue";
import DashboardMarket from "@/pages/dashboard-market/DashboardMarket.vue";
import MirrorTurnover from "@/pages/mirror-turnover/MirrorTurnover.vue";
import AccountLayout from "@/components/admin/AccountLayout.vue";
import App from "@/App.vue";
import MirrorsIcon from "@/assets/img/icons/menu/mirrorsIcon.vue";
import MirrorTurnoverIcon from "@/assets/img/icons/menu/mirrorTurnoverIcon.vue";
import MirrorBalanceIcon from "@/assets/img/icons/menu/mirrorBalanceIcon.vue";
import UsersMenuIcon from "@/assets/img/icons/menu/usersMenuIcon.vue";
import PartnersVolumeIcon from "@/assets/img/icons/menu/partnersVolumeIcon.vue";
import RateCorrectionIcon from "@/assets/img/icons/menu/rateCorrectionIcon.vue";
import RateIcon from "@/assets/img/icons/menu/rateIcon.vue";
import marketDashboardIcon from "@/assets/img/icons/menu/marketDashboardIcon.vue";
import MainDashboardIcon from "@/assets/img/icons/menu/mainDashboardIcon.vue";
import ReportGenerator from "@/pages/report-generator/ReportGenerator.vue";
import ReportGeneratorIcon from "@/assets/img/icons/menu/reportGeneratorIcon.vue";
import BlockOrderReportsIcon from '@/assets/img/icons/menu/blockOrderReportsIcon.vue';
import BlockOrderReports from '@/pages/block-order-reports/BlockOrderReports.vue';
import BlogArticles from '@/pages/blog-articles/BlogArticles.vue';
import ArticleEditor from '@/pages/blog-articles/partials/ArticleEditor.vue';
import BlogIcon from '@/assets/img/icons/menu/BlogIcon.vue';
import ExchangesIconRetro from '@/assets/img/icons/menu/exchangesIconRetro.vue';
import ClientUsersList from '@/pages/clientUsers/ClientUsersList.vue';
import DepositsIcon from '@/assets/img/icons/menu/depositsIcon.vue';
import DepositsList from '@/pages/deposits/DepositsList.vue';
import NetworkList from '@/pages/coins/NetworkList.vue';
import EmailLetters from '@/pages/email-letters/EmailLetters.vue';
import LetterEditor from '@/pages/email-letters/partials/LetterEditor.vue';
import BlackList from '@/pages/black-list/BlackList.vue';
import BlackListNew from '@/pages/black-list/BlackListNew.vue';

const routes = [

    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Login',
            icon: '',
            isMenu: false, isProd: true
        },
        component: Login
    },
    {
        path: '/',
        name: 'Dashboards',
        meta: {
            title: 'Dashboards',
            icon: DashboardIcon,
            isMenu: true,
            isDrop: true,
        },
        component: App,
        redirect: { name: 'Main dashboard' },
        children: [
            {
                path: 'dashboard',
                name: 'Main dashboard',
                meta: {
                    title: 'Main dashboard',
                    icon: MainDashboardIcon,
                    isMenu: true,
                },
                component: Dashboard,
            },
            {
                path: 'dashboard/market',
                name: 'Market dashboard',
                meta: {
                    title: 'Dashboard Market',
                    icon: marketDashboardIcon,
                    isMenu: true,
                },
                component: DashboardMarket,
            },
        ],
    },
    {
        path: '/exchanges',
        name: 'Exchanges',
        meta: {
            title: 'Exchanges',
            icon: ExchangesIcon,
            isMenu: true, isProd: false
        },
        component: ExchangesList2
    },
    {
        path: '/exchanges-retro',
        name: 'Exchanges retro',
        meta: {
            title: 'Exchanges retro',
            icon: ExchangesIconRetro,
            isMenu: true, isProd: true
        },
        component: ExchangesList
    },
    {
        path: '/exchanges/1inch',
        name: 'Exchanges 1inch',
        meta: {
            title: 'Exchanges 1inch',
            icon: ExchangesIcon,
            isMenu: false, isProd: true
        },
        component: ExchangesList1inch
    },
    {
        path: '/exchanges/multi',
        name: 'Exchanges Multi',
        meta: {
            title: 'Exchanges Multi',
            icon: ExchangesIcon,
            isMenu: false, isProd: true
        },
        component: ExchangesListMulti
    },
    {
        path: '/exchanges/:id',
        name: 'Exchange modal',
        meta: {
            title: 'Exchange modal',
            icon: '',
            isMenu: false, isProd: true
        },
        component: ExchangesList,
        pathToRegexpOptions: {strict: true},
    },
    {
        path: '/exchanges/multi/:id',
        name: 'Exchange Multi modal',
        meta: {
            title: 'Exchange Multi modal',
            icon: '',
            isMenu: false, isProd: true
        },
        component: ExchangesListMulti,
        pathToRegexpOptions: {strict: true},
    },
    {
        path: '/deposits',
        name: 'Deposits',
        meta: {
            title: 'Deposits List',
            icon: DepositsIcon,
            isMenu: true, isProd: true
        },
        component: DepositsList,
    },
    {
        path: '/coins/',
        name: 'Coins',
        meta: {
            title: 'Coins',
            icon: CoinsIcon,
            isMenu: true,
            isDrop: true
        },
        component: App,
        children: [
            {
                path: 'coins',
                name: 'Coins list',
                meta: {
                    title: 'Coins list',
                    icon: CoinsIcon,
                    isMenu: true,
                },
                component: CoinsList
            },
            {
                path: 'networks',
                name: 'Networks list',
                meta: {
                    title: 'Networks list',
                    icon: CoinsIcon,
                    isMenu: true,
                },
                component: NetworkList
            },
            {
                path: '/coins/1inch',
                name: 'Coin1inch',
                meta: {
                    title: 'Coins List 1inch',
                    icon: CoinsIcon,
                    isMenu: false, isProd: true
                },
                component: Coins1inchList
            },
            {
                path: '/coins/1inch/:id',
                name: 'Coin 1inch edit modal',
                meta: {
                    title: 'Coin 1inch edit',
                    icon: '',
                    isMenu: false, isProd: true
                },
                component: Coins1inchList,
                pathToRegexpOptions: {strict: true},
            },
            {
                path: '/coins/:id',
                name: 'Coins edit modal',
                meta: {
                    title: 'Coin edit',
                    icon: '',
                    isMenu: false, isProd: true
                },
                component: CoinsList,
                pathToRegexpOptions: {strict: true},
            },
        ]
    },
    {
        path: '/address-locked',
        name: 'Address locked',
        meta: {
            title: 'Address locked',
            icon: UserIcon,
            isMenu: false, isProd: true
        },
        component: AddressLockedList
    },
    {
        path: '/users',
        name: 'Users',
        meta: {
            title: 'Users',
            icon: UsersMenuIcon,
            isMenu: true, isProd: true
        },
        component: UsersList
    },
    {
        path: '/client-users',
        name: 'Client Users',
        meta: {
            title: 'Client users',
            icon: UsersMenuIcon,
            isMenu: true, isProd: true
        },
        component: ClientUsersList
    },
    {
        path: '/mirrors/',
        name: 'Mirrors',
        meta: {
            title: 'Mirrors',
            icon: MirrorsIcon,
            isMenu: true,
            isDrop: true
        },
        component: App,
        children: [
            {
                path: 'turnover',
                name: 'Mirror turnover',
                meta: {
                    title: 'Mirror turnover',
                    icon: MirrorTurnoverIcon,
                    isMenu: true,
                },
                component: MirrorTurnover
            },
            {
                path: 'mirror',
                name: 'Balance mirror',
                meta: {
                    title: 'Mirror balance',
                    icon: MirrorBalanceIcon,
                    isMenu: true,
                },
                component: BalanceMirror
            },
        ]
    },
    {
        path: '/partners/',
        name: 'Partners',
        meta: {
            title: 'Partners',
            icon: UserIcon,
            isMenu: true,
            isDrop: true
        },
        component: App,
        children: [
            {
                path: 'pay',
                name: 'Partners pay',
                meta: {
                    title: 'Partners pay',
                    icon: PartnersPayIcon,
                    isMenu: true,
                },
                component: PaymentsList
            },
            {
                path: 'volume',
                name: 'Partners volume',
                meta: {
                    title: 'Partners volume',
                    icon: PartnersVolumeIcon,
                    isMenu: true,
                },
                component: PartnerVolume
            },
            {
                path: 'stats',
                name: 'Partners stats',
                meta: {
                    title: 'Partners Stats',
                    icon: PartnerStatsIcon,
                    isMenu: true,
                },
                component: PartnerStat
            },
            {
                path: 'programs',
                name: 'Partners plan',
                meta: {
                    title: 'Partners plan',
                    icon: PartnersProgramIcon,
                    isMenu: true,
                },
                component: ProgramsList
            },
        ]
    },
    {
        path: '/blog-articles/',
        name: 'Blog Articles',
        meta: {
            title: 'Blog Articles',
            icon: BlogIcon,
            isMenu: true, isProd: false
        },
        component: BlogArticles,
    },
    {
        path: '/blog-articles/article-editor/:article/',
        name: 'Article Editor',
        props: true,
        meta: {
            title: 'Article Editor',
        },
        component: ArticleEditor
    },
    {
        path: '/email-templates/',
        name: 'Email templates',
        meta: {
            title: 'Email templates',
            icon: BlogIcon,
            isMenu: true, isProd: false
        },
        component: EmailLetters,
    },
    {
        path: '/email-templates/template-editor/:template/:activeTab',
        name: 'Template Editor',
        props: true,
        meta: {
            title: 'Template Editor',
        },
        component: LetterEditor
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: {
            title: 'Settings',
            icon: SettingsIcon,
            isMenu: true, isProd: true
        },
        component: Settings
    },
    {
        path: '/sub-accounts',
        name: 'Sub accounts',
        meta: {
            title: 'Sub accounts',
            icon: UsersIcon,
            isMenu: false, isProd: true
        },
        component: SubAccounts
    },
    {
        path: '/sub-accounts/:id',
        name: 'Sub account edit modal',
        meta: {
            title: 'Sub account edit',
            icon: '',
            isMenu: false, isProd: true
        },
        component: SubAccounts,
        pathToRegexpOptions: {strict: true},
    },
    {
        path: '/user-balance',
        name: 'User Balances',
        meta: {
            title: 'User Balances',
            icon: UsersIcon,
            isMenu: false, isProd: true
        },
        component: UserBalances
    },
    {
        path: '/widget',
        name: 'Widget',
        meta: {
            title: 'Widget',
            icon: WidgetIcon,
            isMenu: true, isProd: true
        },
        component: WidgetCreate
    },
    {
        path: '/balance/system',
        name: 'Balance system',
        meta: {
            title: 'Balance system',
            icon: UsersIcon,
            isMenu: false, isProd: true
        },
        component: Balance1inchList
    },
    {
        path: '/balance/total',
        name: 'Balance total',
        meta: {
            title: 'Balance total',
            icon: UsersIcon,
            isMenu: false, isProd: true
        },
        component: Balance1inchTotal
    },
    {
        path: '/rate',
        name: 'Rate',
        meta: {
            title: 'Rate',
            icon: RateIcon,
            isMenu: true,
            isDrop: true
        },
        component: App,
        children: [
            {
                path: '/rate-request/pairs',
                name: 'Rate request pairs',
                meta: {
                    title: 'Rate request by pairs',
                    icon: RateRequestIcon,
                    isMenu: false, isProd: true
                },
                component: RateRequest,
            },
            {
                path: '/rate-request/not-found',
                name: 'Rate request coins',
                meta: {
                    title: 'Rate request by coins',
                    icon: RateRequestIcon,
                    isMenu: false, isProd: true
                },
                component: RateRequestCoins,
            },
            {
                path: '/rate-request/date',
                name: 'Rate request',
                meta: {
                    title: 'Rate request date',
                    icon: RateRequestIcon,
                    isMenu: true, isProd: true
                },
                component: RateRequestDate,
            },
            {
                path: '/rate-correction',
                name: 'Rate correction',
                meta: {
                    title: 'Rate correction',
                    icon: RateCorrectionIcon,
                    isMenu: true, isProd: true
                },
                component: RateCorrection
            },
            {
                path: '/rate-correction/create',
                name: 'Rate correction create',
                meta: {
                    title: 'Rate correction create',
                    icon: RateCorrectionIcon,
                    isMenu: false, isProd: true
                },
                component: RateCorrection
            },
            {
                path: '/rate-correction/:id?',
                name: 'Rate correction edit',
                meta: {
                    title: 'Rate correction edit',
                    icon: RateCorrectionIcon,
                    isMenu: false, isProd: true
                },
                component: RateCorrection
            },
        ]

    },
    {
        path: '/trade/',
        name: 'Trade',
        meta: {
            title: 'Trade',
        },
        children: [
            {
                path: ':id?/balance',
                name: 'Trade balance',
                component: TradeBalancePage,
            },
            {
                path: ':id?/orders',
                name: 'Trade orders',
                component: TradeOrdersPage
            },
            {
                path: ':id?/withdrawals',
                name: 'Trade withdrawals',
                component: TradeWithdrawalPage
            },
            {
                path: ':id?/create-order-:coin',
                name: 'Create order',
                component: CreateOrder
            },
            {
                path: ':id?/create-withdrawal',
                name: 'Create withdrawal',
                component: CreateWithdrawal
            },
            {
                path: ':id?/create-withdrawal-:coin?',
                name: 'Create withdrawal by',
                component: CreateWithdrawal
            },
            {
                path: ':id?/create-transfer-:coin?&modeFrom=:mode?',
                name: 'Create transfer',
                component: CreateTransfer
            },
            {
                path: ':id?/collect-profit',
                name: 'Collect profit',
                component: CollectProfit
            },
            {
                path: '/accounts',
                name: 'Trade accounts',
                component: TradeAccounts
            },
            {
                path: ':id?/add-account',
                name: 'Create account',
                component: CreateAccount
            },
            {
                path: ':id?/create-deposit',
                name: 'Create deposit',
                component: CreateDeposit
            },
            {
                path: ':id?/create-deposit-:coin?',
                name: 'Create deposit by',
                component: CreateDeposit
            },
        ]
    },
    {
        path: '/report-generator',
        name: 'Report generator',
        component: ReportGenerator,
        meta: {
            title: 'Report generator',
            icon: ReportGeneratorIcon,
            isMenu: true,
            isDrop: false
        },
    },
    {
        path: '/black-list',
        name: 'Black list',
        component: BlackList,
        meta: {
            title: 'Black list',
            icon: ReportGeneratorIcon,
            isMenu: true,
            isDrop: false
        },
    },
    {
        path: '/black-list/black-list-new-record/:activeTab',
        name: 'Black list new record',
        props: true,
        component: BlackListNew,
        meta: {
            title: 'Black list new record',
        },
    },
    {
        path: '/block-order-reports',
        name: 'Block Order Reports',
        component: BlockOrderReports,
        meta: {
            title: 'Block Order Reports',
            icon: BlockOrderReportsIcon,
            isMenu: true,
            isDrop: false
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const DEFAULT_TITLE = ' | Swapuz admin';
router.beforeEach((to) => {
    document.title = to.meta.title + DEFAULT_TITLE;
});
router.afterEach(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
    window.scrollTo(0, 0);
    setTimeout(() => {
        document.documentElement.style.scrollBehavior = '';
    }, 1000);
});


export default router;
