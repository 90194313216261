<template>
  <div class="percent-bar__networks-block">
    <div class="table-block_table--row--item percent-bar percent-bar_total">
      <div
        class="percent-bar__item percent-bar__item-node"
        :class="{
          'percent-bar__item-red': nodePercentage >= 0 && nodePercentage <= 20,
          'percent-bar__item-green': nodePercentage >= 21 && nodePercentage <= 80,
          'percent-bar__item-gray': nodePercentage >= 81 && nodePercentage <= 100

        }"
        @click="token.name === 'USDC'
          ? reBalanceHandler('node', 'USDC_ETH')
          : token.name === 'DAI'
            ? reBalanceHandler('node', 'DAI_ETH')
            : null"
      >
        <replace-icon v-if="token.name === 'USDC' || token.name === 'DAI'" />
        {{ nodePercentage }}%
      </div>
      <div
        class="percent-bar__item percent-bar__item-market"
        :class="{
          'percent-bar__item-red': exchangePercentage >= 0 && exchangePercentage <= 20,
          'percent-bar__item-green': exchangePercentage >= 21 && exchangePercentage <= 80,
          'percent-bar__item-gray': exchangePercentage >= 81 && exchangePercentage <= 100
        }"
        @click="token.name === 'USDC'
            ? reBalanceHandler('market', 'USDC_ETH')
            : token.name === 'DAI'
              ? reBalanceHandler('market', 'DAI_ETH')
              : null"
      >
        <replace-icon v-if="token.name === 'USDC' || token.name === 'DAI'" />
        {{ exchangePercentage }}%
      </div>
    </div>

    <div
      v-for="(item, index) in filteredNetworks"
      :key="index"
      class="table-block_table--row--item percent-bar percent-bar_networks"
    >
      <div
        class="percent-bar__item percent-bar__item-node"
        :class="{
          'percent-bar__item-red': percentageHandler(item) >= 0 && percentageHandler(item) <= 20,
          'percent-bar__item-green': percentageHandler(item) >= 21 && percentageHandler(item) <= 80,
          'percent-bar__item-gray': percentageHandler(item) >= 81 && percentageHandler(item) <= 100

        }"
        @click="reBalanceHandler('node',item.name)"
      >
        <div v-html="nodePercentageHandler(item)" />
        <replace-icon />
      </div>
      <div
        class="percent-bar__item percent-bar__item-market"
        :class="{
          'percent-bar__item-red': exchangePercentage >= 0 && exchangePercentage <= 20,
          'percent-bar__item-green': exchangePercentage >= 21 && exchangePercentage <= 80,
          'percent-bar__item-gray': exchangePercentage >= 81 && exchangePercentage <= 100
        }"
        @click="reBalanceHandler('market',item.name)"
      >
        <replace-icon />
        {{ exchangePercentage }}%
      </div>
    </div>
  </div>
</template>

<script>
import ReplaceIcon from '@/assets/img/icons/replaceIcon.vue';

export default {
  components: {ReplaceIcon},
  props: {
    token: {
      type: Object,
      required: true
    },
    chartDataMounted: {
      type: Object,
      required: true
    },
    reBalanceHandler: {
      type: Function,
      required: true,
    },
  },
  computed: {
    nodeBalanceAvailable() {
      return this.chartDataMounted.node[this.token].balanceUsdt;
    },
    filteredNetworks() {
      return this.token.networks.filter(network => network.name !== 'USDC_ETH' && network.name !== 'DAI_ETH');
    },
    exchangeBalanceAvailable() {
      return this.chartDataMounted.exchange[this.token.name].balanceUsdt;
    },
    totalBalanceAvailable() {
      return this.totalNetworkBalance + this.exchangeBalanceAvailable;
    },
    nodePercentage() {
      if (this.totalBalanceAvailable === 0 && this.totalNetworkBalance === 0) {
        return 0
      }
      return Math.round((this.totalNetworkBalance / this.totalBalanceAvailable) * 100);
    },
    exchangePercentage() {
      return 100 - this.nodePercentage;
    },
    totalNetworkBalance() {
      let totalBalance = 0;
      for (const network of this.token.networks) {
        const networkName = network.name;
        totalBalance += this.chartDataMounted.node[networkName].balanceUsdt ;
      }

      return totalBalance ;
    }
  },
  methods: {
    calculateNetworkBalances() {
      const totalBalance = this.totalNetworkBalance + this.chartDataMounted.exchange[this.token.name].balanceUsdt;
      let percentages = {};

      for (const network of this.token.networks) {
        let networkTRXBalance;
        let networkETHBalance;

        networkTRXBalance = this.chartDataMounted.node.USDT_TRX.balanceUsdt;
        networkETHBalance = this.chartDataMounted.node.USDT_ETH.balanceUsdt;

        const percentageETH = (networkETHBalance / totalBalance) * 100;
        const percentageTRX = (networkTRXBalance / totalBalance) * 100;

        percentages = {
          eth: percentageETH.toFixed(0),
          trx: percentageTRX.toFixed(0)
        };
      }

      return percentages
    },
    nodePercentageHandler(item) {
      const percentages = this.calculateNetworkBalances();
      const firstPercentage = percentages.eth;
      const secondPercentage = percentages.trx;

      return `
    <span class="${item.name === 'USDT_ETH' ? 'large-font' : 'small-font'}">${firstPercentage}%</span>
    <span class="${item.name === 'USDT_TRX' ? 'large-font' : 'small-font'}">${secondPercentage}%</span>
  `;
    },
    percentageHandler(item) {
      let token = item.network.toLowerCase();
      const percentages = this.calculateNetworkBalances();

      return percentages[token]
    }


  }
};
</script>

<style lang="scss">
.percent-bar {
  display: flex;
  flex-direction: row;
  gap: 2px;
  border-radius: 4px;
  overflow: visible !important;

  &__networks-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    width: 64px;
    height: 28px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 2px;
    padding: 4px 0 7px 0;
    box-sizing: border-box;

    &-node, &-market {
      cursor: pointer;
      position: relative;

      >svg {
        position: absolute;
        display: none;
      }

      &:hover {
        >svg {
          display: block;
        }
      }
    }

    .small-font {
      font-size: 10px;
      opacity: 0.64;
    }

    &-green {
      color: #00B18B;
      background: rgba(0, 177, 139, 0.12);
    }

    &-red {
      color: #FF603D;
      background: rgba(255, 96, 61, 0.12);
    }

    &-gray {
      color: #9FA2B4;
      background: rgba(159, 162, 180, 0.12);
    }
  }
}
</style>
