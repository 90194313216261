import service from '../plugins/request';

class EmailLettersService {

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getEmailLettersAll() {
        return service.get('/api/EmailTemplate/all')
    }

    getEmailLetterById(id) {
        return service.get(`/api/EmailTemplate/${id}`)
    }

    updateOrCreateEmailLetter(query) {
        return service.post('/api/EmailTemplate/updateOrCreate',query)
    }

    getLanguagesAll() {
        return service.get('/api/Language/all')
    }

    getLanguageById(id) {
        return service.get(`/api/Language/${id}`)
    }

    updateOrCreateLanguage(query) {
        return service.post('/api/Language/updateOrCreate',query)
    }
}


export default new EmailLettersService();
