import service from '../plugins/request';

class BlackListService {

    getWithdrawAll() {
        return service.get('/api/Blacklist/withdraw/All')
    }

    getDepositAll() {
        return service.get('/api/Blacklist/deposit/All')
    }

    addWithdrawalRecord(query) {
        return service.post('/api/Blacklist/withdraw/Add',query)
    }

    addDepositRecord(query) {
        return service.post('/api/Blacklist/deposit/add',query)
    }

    setWithdrawalRecord(id,isActive) {
        return service.patch(`/api/Blacklist/withdraw/Set/${id}/${isActive}`)
    }

    setDepositRecord(id,isActive) {
        return service.patch(`/api/Blacklist/deposit/Set/${id}/${isActive}`)
    }
}


export default new BlackListService();
