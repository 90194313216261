export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        noSort: true,
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Lang',
        sort: '',
        noSort: false,
        key: 'lang',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Key',
        sort: '',
        noSort: false,
        key: 'key',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Edit',
        sort: '',
        noSort: false,
        key: 'edit',
        description: 'Trade round is the latest round of trade negotiations'
    },
];
