<template>
  <tr
    class="email-letters-item-tr"
  >
    <td
      class="table-id"
    >
      {{ letter.id }}
    </td>
    <td
      class="table-lang"
    >
      {{ letter.langCode }}
    </td>
    <td
      class="table-author"
    >
      {{ letter.key }}
    </td>
    <td class="table-edit">
      <button
        class="edit"
        @click="editLetter"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
            fill="#0065FF"
          />
        </svg>
      </button>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "EmailLetterItem",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    letter: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    activeTab: {
      required: true
    }
  },
  data() {
    return {
      mainDomain: process.env.VUE_APP_MAIN_DOMAIN,
      isStatusDropdownOpen: false,
      currentStatusIndex: null
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    document.addEventListener('click', this.statusClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.statusClickOutside);
  },
  methods: {
    editLetter() {
      this.$router.push({ name: 'Template Editor', params: {template: this.letter.id, activeTab: this.activeTab} });
    },
    dataFormatting(value, format) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(format)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    openLink() {
      if (this.article.status === 1) {
        window.open(`${this.mainDomain}/blog/${this.article.id}-${this.article.slag}`, '_blank');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

  .email-letters-item-tr {
    height: 56px;
    border-bottom: 1px solid #EAEFF4;
    transition: .3s;

    &:hover {
      background: #F5F9FF;
    }

    td {
      position: relative;
      color: $black-color1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.28px;

      span {
        color: $gray-color;
      }
    }

    td.table-id {
      padding-left: 32px;
    }

    td.table-edit {
      padding-right: max(2.62%, 32px);

      button {
        display: flex;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        outline: none;
        border: none;
        cursor: pointer;
        box-sizing: border-box;
        transition: 0.3s;
      }

      button.edit, button.link {
        justify-content: center;
        background: $light-gray-color;
      }

      button.link.disabled {
        cursor: not-allowed;

        svg path {
          fill: #A1BBD9;
        }
      }
    }
  }

</style>
