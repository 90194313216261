<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
            <path d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H2ZM2 16H18V2H2V16ZM4 14H16V12H4V14ZM4 10H8V4H4V10ZM10 10H16V8H10V10ZM10 6H16V4H10V6Z" fill="#0065FF"/>
          </svg>
          Email templates
        </h2>
      </div>
    </template>
    <template #content>
      <div
        v-if="emailLettersData && languagesData"
        class="main-block email-letters"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="table-block">
          <div
            ref="stickyBlock"
            class="table-block-header"
            :class="{ sticky: false }"
          >
            <div class="table-block-header-title">
              List of {{activeTab}}
            </div>

            <div class="filters-component-container">
              <div class="filters-component-tabs">
                <span
                  :class="{'active': activeTab === 'letters'}"
                  @click="activeTabHandler('letters')"
                >
                  Letters
                </span>
                <span
                  :class="{'active': activeTab === 'languages'}"
                  @click="activeTabHandler('languages')"
                >
                  Languages
                </span>
              </div>
              <!--              <button class="new-post-button" @click="this.$router.push({ name: 'Article Editor', params: {article: 'new'} })">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H11.6042L9.9375 4.16667H4.16667V15.8333H15.8333V10.0417L17.5 8.375V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM7.5 12.5V8.95833L15.1458 1.3125C15.3125 1.14583 15.5 1.02083 15.7083 0.9375C15.9167 0.854167 16.125 0.8125 16.3333 0.8125C16.5556 0.8125 16.7674 0.854167 16.9687 0.9375C17.1701 1.02083 17.3542 1.14583 17.5208 1.3125L18.6875 2.5C18.8403 2.66667 18.9583 2.85069 19.0417 3.05208C19.125 3.25347 19.1667 3.45833 19.1667 3.66667C19.1667 3.875 19.1285 4.07986 19.0521 4.28125C18.9757 4.48264 18.8542 4.66667 18.6875 4.83333L11.0417 12.5H7.5ZM9.16667 10.8333H10.3333L15.1667 6L14.5833 5.41667L13.9792 4.83333L9.16667 9.64583V10.8333Z" fill="white"/>
                              </svg>
                              Create new
                            </button>-->
            </div>
          </div>
          <div class="table-block-table">
            <Transition name="committee">
              <table class="table">
                <thead>
                  <tr class="table-headers">
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="tokenTo = index"
                        @mouseleave="tokenTo = null"
                      >
                        {{ item.name }}
                        <span v-if="item.isSortable">
                          <sort-passive v-if="!item.sort" />
                          <sort-a-z v-if="item.sort === 'ASC'" />
                          <sort-z-a v-if="item.sort === 'DESC'" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="emailLettersData.length && activeTab === 'letters'">
                  <EmailLetterItem
                    v-for="letter in emailLettersData"
                    :key="letter.key"
                    :letter="letter"
                    :active-tab="activeTab"
                  />
                </tbody>
                <tbody v-if="languagesData.length && activeTab === 'languages'">
                <EmailLetterItem
                  v-for="letter in languagesData"
                  :key="letter.key"
                  :letter="letter"
                  :active-tab="activeTab"
                />
                </tbody>
              </table>
            </Transition>
            <Transition name="committee">
              <no-data-block v-if="!emailLettersData.length && !languagesData.length && !isLoading" />
            </Transition>
          </div>
<!--          <paged-pagination
            v-if="articlesData.length && !isLoading && maxPage"
            :total="maxPage"
            :current="page"
            @change-page="pageHandler"
            @prev-page="pageHandler"
            @next-page="pageHandler"
          />-->
        </div>
      </div>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import ExchangesIcon from '@/assets/img/icons/menu/exchangesIcon.vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import { TABLE_TH} from '@/pages/email-letters/emailLettersData';
import StatusFilter from '@/pages/blog-articles/partials/StatusFilter.vue';
import DatesFilter from '@/components/admin/DatesFilter.vue';
import TagsFilter from '@/pages/blog-articles/partials/TagsFilter.vue'
import FilterClose from '@/icons/filterClose.vue';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';
import PagedPagination from '@/components/admin/PagedPagiantion.vue';
import BlogListItem from '@/pages/blog-articles/partials/BlogListItem.vue';
import BlogService from '@/services/blog.service';
import UserService from '@/services/user.service';
import AdminFilter from '@/pages/blog-articles/partials/AdminFilter.vue';
import NoDataBlock from '@/components/admin/NoDataBlock.vue';
import emailLettersService from '@/services/email-letters.service';
import EmailLettersService from '@/services/email-letters.service';
import EmailLetterItem from '@/pages/email-letters/partials/EmailLetterItem.vue';

export default defineComponent({
  components: {
    EmailLetterItem,
    NoDataBlock,
    SortZA, SortPassive, SortAZ, Loading, AccountLayoutNew},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      emailLettersData: [],
      languagesData: [],
      tableTh: TABLE_TH,
      isLoading: true,
      maxPage: 0,
      page: 1,
      tokenTo: false,
      isUpdate: false,
      updateText: '',
      activeTab: 'letters',
      // sticky header
      isSticky: false,
      stickyOffset: 0
    }
  },
  mounted() {
    this.mountList()
    this.stickyOffset = this.$refs.stickyBlock.getBoundingClientRect().top;
  },
  beforeUnmount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    mountList() {
      EmailLettersService.getEmailLettersAll().then((result) => {
        if (result.status === 200) {
          this.emailLettersData = result.data.result.sort((a, b) => a.langCode.localeCompare(b.langCode));
          this.isLoading = false;
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get letters error')
      })


      EmailLettersService.getLanguagesAll().then((result) => {
        if (result.status === 200) {
          this.languagesData = result.data.result.sort((a, b) => a.langCode.localeCompare(b.langCode));
          this.isLoading = false;
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get lang error')
      })
    },
    activeTabHandler(tab) {
      this.activeTab = tab;
    },
    // sticky header
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scrollTop - 100 >= this.stickyOffset;
    },
    // sort
    sortData(item) {

    },
  }
})
</script>

<style scoped lang="scss">
//@import "../../assets/styles/pages/email-letters/email-letters.scss";

.email-letters {
  .table-block-header {
    flex-direction: row;
    @media (max-width: 1140px) {
      flex-direction: column !important;
    }
  }

  .table-block-table table {
    @media (max-width: 1079px) {
      width: 100%;
    }
  }

  tr.table-headers th.table-id {
    padding-left: 32px;
    width: 40px;
  }

  tr.table-headers th.table-edit {
    padding-right: 32px;
    width: 40px;
  }


  .filters-component-container {
    width: 100%;

    .filters-component-tabs {
      display: flex;
      gap: 4px;

      span {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 6px 12px 6px;
        height: 32px;
        min-width: fit-content;
        font-size: 12px;
        color: #0065FF;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background: #EBF3FF;
        border-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          background: #E0EDFF;
        }
      }

      span.active {
        background: #0065FF;
        color: white;
      }
    }
  }

  .table-block-header.sticky {
    position: fixed;
    top: 0;
    background: #fff;
    width: calc(100% - 385px);
    z-index: 10;

    @media (max-width: 1079px) {
      left: 0;
      width: calc(100% - 32px);
    }
  }

  .table-block-header-title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 32px;
    min-width: fit-content !important;
  }
}
</style>
