<template>
  <div
    class="custom-select"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div
      class="selected"
      :class="{ open: open }"
      @click="open = !open"
    >
      {{ valueModel }}
    </div>
    <div
      v-if="options.length"
      class="items"
      :class="{ selectHide: !open }"
    >
      <div class="item">
        <div
          v-for="(option, index) of options"
          :key="index"
          @click="selectHandler(option)"
        >
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueInput: {
      type: String,
      required: false,
      default: '',
    }
  },
  emits: ['change-select'],
  data() {
    return {
      open: false,
      valueModel: '',
    };
  },
  mounted() {
    this.open = this.isOpen;
    this.valueModel = this.valueInput;
  },
  methods: {
    selectHandler(option) {
      this.valueModel = option;
      this.open = false;

      this.$emit("change-select", {
        option: option,
        data: this.data,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  margin-bottom: 32px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 14px;
  line-height: 18px;
  color: #1B1A1C;
  border-radius: 8px;
  border: 1px solid #EAEFF4;
  height: 44px;
  box-sizing: border-box;

  .selectHide {
    display: none;
  }

  .selected {
    color: #1B1A1C;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    padding: 12px 16px;

    .open {
      background: rgba(159, 162, 180, 0.06);
      border-radius: 8px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 21px;
      right: 21px;
      width: 0;
      margin-left: 7px;
      height: 0;
      border: 5px solid transparent;
      border-color: #9FA2B4 transparent transparent transparent;
    }
  }

  .items {
    color: #1B1A1C;
    overflow: hidden;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(15, 0, 58, 0.12);
    border-radius: 12px;
    z-index: 1;
    margin-top: 4px;
    left: 0;
    width: 100%;
    line-height: 50px;

    @media (max-width: 1079px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      z-index: 999;
      background: rgba(67, 40, 164, 0.54);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }

    .item {
      color: #1B1A1C;
      padding-left: 12px;
      cursor: pointer;
      user-select: none;
      background: none;

      @media (max-width: 1079px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        box-shadow: 0 6px 24px rgba(27, 26, 28, 0.16);
        border-radius: 16px 16px 0 0;
        width: 100%;
        padding-bottom: 20px;

        .title {
          padding: 20px 20px 8px 20px;
          cursor: default;

          span {
            padding-left: 5px;
            color: #9FA2B4;
            font-weight: 700;
          }
        }
      }

      &-dropdown {
        &:hover {
          background: rgba(27, 26, 28, 0.03);
        }
      }

      .mob-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 24px;
      }
    }

    .active {
      color: #0A68F7;
      font-weight: 600;
    }

    div {
      text-align: left;
      width: 100%;

      .default {
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
      }

      .non-default {
        padding-left: 20px;
      }
    }
  }
}

</style>
