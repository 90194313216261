<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H2ZM2 16H18V2H2V16ZM4 14H16V12H4V14ZM4 10H8V4H4V10ZM10 10H16V8H10V10ZM10 6H16V4H10V6Z"
              fill="#0065FF"
            />
          </svg>
          Black List
        </h2>
      </div>
    </template>
    <template #content>
      <main class="main-block black-list-editor">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="header-wrapper">
          <h1 class="black-list-editor-title">
            New record
          </h1>
          <button
            class="close-button"
            @click="$router.push({ name: 'Black list' })"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.33366 15.8333L4.16699 14.6666L8.83366 9.99996L4.16699 5.33329L5.33366 4.16663L10.0003 8.83329L14.667 4.16663L15.8337 5.33329L11.167 9.99996L15.8337 14.6666L14.667 15.8333L10.0003 11.1666L5.33366 15.8333Z"
                fill="#0065FF"
              />
            </svg>
          </button>
        </div>
        <section
          class="black-list-editor-form-container"
        >
          <div>
            <h4 class="sub-title">
              Address
            </h4>
            <label>
              <input
                v-model="newRecordData.address"
                type="text"
                class="editor-form-input"
                :class="{'invalid': isAddressError}"
                @focus="isAddressError = false"
              >
              <span
                v-if="isAddressError"
                class="invalid-text"
              >
                Address cannot be empty.
              </span>
            </label>

            <h4 class="sub-title">
              Network
            </h4>
            <CustomSelect
              :options="selectOptions"
              :value-input="newRecordData.network"
              @change-select="({option}) => newRecordData.network = option"
            />

            <h4 class="sub-title">
              Comment
            </h4>
            <label>
              <input
                v-model="newRecordData.comment"
                type="text"
                class="editor-form-input"
                :class="{'invalid': isCommentError}"
                @focus="isCommentError = false"
              >
              <span
                v-if="isCommentError"
                class="invalid-text"
              >
                Comment cannot be empty.
              </span>
            </label>
          </div>
        </section>

        <section class="action-section">
          <div class="action-buttons-wrapper">
            <button @click="$router.push({ name: 'Black list' })">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 19V17H14.1C15.15 17 16.0625 16.6667 16.8375 16C17.6125 15.3333 18 14.5 18 13.5C18 12.5 17.6125 11.6667 16.8375 11C16.0625 10.3333 15.15 10 14.1 10H7.8L10.4 12.6L9 14L4 9L9 4L10.4 5.4L7.8 8H14.1C15.7167 8 17.1042 8.525 18.2625 9.575C19.4208 10.625 20 11.9333 20 13.5C20 15.0667 19.4208 16.375 18.2625 17.425C17.1042 18.475 15.7167 19 14.1 19H7Z"
                  fill="#0065FF"
                />
              </svg>
              Exit
            </button>
            <button
              class="publish-button"
              @click="newRecordHandler"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 7V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H17L21 7ZM19 7.85L16.15 5H5V19H19V7.85ZM12 18C12.8333 18 13.5417 17.7083 14.125 17.125C14.7083 16.5417 15 15.8333 15 15C15 14.1667 14.7083 13.4583 14.125 12.875C13.5417 12.2917 12.8333 12 12 12C11.1667 12 10.4583 12.2917 9.875 12.875C9.29167 13.4583 9 14.1667 9 15C9 15.8333 9.29167 16.5417 9.875 17.125C10.4583 17.7083 11.1667 18 12 18ZM6 10H15V6H6V10ZM5 7.85V19V5V7.85Z"
                  fill="#fff"
                />
              </svg>
              Save
            </button>
          </div>
        </section>
      </main>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import BlackListService from '@/services/black-list.service';
import CustomSelect from './CustomSelect.vue';


export default defineComponent({
  components: {
    CustomSelect,
    Loading, AccountLayoutNew
  },
  props: {
    activeTab: {
      type: String,
      required: true
    }
  },
  setup() {


    return {

    }
  },
  data() {
    return {
      isLoading: false,
      newRecordData: {
        address: "",
        comment: "",
        network: "ETH",
        isActive: true
      },
      isNetworkError: false,
      isAddressError: false,
      isCommentError: false,
      selectOptions: [
        'BTC',
        'ETH',
        'TRX',
        'XMR',
        'BSC',
        'ARBITRUM',
        'BASE']
    }
  },
  mounted() {

  },
  beforeUnmount() {

  },
  methods: {
    newRecordHandler() {
      if (this.newRecordData.address === "") {
        this.isAddressError = true;
      }

      if (this.newRecordData.comment === "") {
        this.isCommentError = true;
      }

      if (this.newRecordData.network === "") {
        this.isNetworkError = true;
      }

      if (this.isNetworkError || this.isCommentError || this.isAddressError) {
        return false
      }

      if (this.activeTab === 'deposits') {
        BlackListService.addDepositRecord(this.newRecordData).then((result) => {
          if (result.status === 200) {
            this.$router.push({ name: 'Black list' })
          }
        }).catch((error) => {
          console.log(error.response.data.result)
        })
      }

      if (this.activeTab === 'withdrawal') {
        BlackListService.addWithdrawalRecord(this.newRecordData).then((result) => {
          if (result.status === 200) {
            this.$router.push({ name: 'Black list' })
          }
        }).catch((error) => {
          console.log(error.response.data.result)
        })
      }
    },
    successPopup(name,value) {
      this.$store.commit('setSuccess', {
        name: name,
        value: value,
      });
    },
  }
})
</script>

<style lang="scss">
@import "../../assets/styles/pages/black-list/black-list-editor";
</style>
